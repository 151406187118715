import React from "react";

const Folder = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.96643 2.25347H10.0131C12.4731 2.25347 13.6731 3.56681 13.6664 6.26014V9.50681C13.6664 12.0801 12.0798 13.6668 9.49977 13.6668H4.4931C1.92643 13.6668 0.333099 12.0801 0.333099 9.50014V4.49347C0.333099 1.73347 1.55977 0.333473 3.97977 0.333473H5.0331C5.65377 0.326806 6.2331 0.613473 6.6131 1.10014L7.19977 1.88014C7.38643 2.11347 7.66643 2.25347 7.96643 2.25347ZM3.91315 9.19347H10.0865C10.3598 9.19347 10.5798 8.9668 10.5798 8.69347C10.5798 8.41347 10.3598 8.19347 10.0865 8.19347H3.91315C3.63315 8.19347 3.41315 8.41347 3.41315 8.69347C3.41315 8.9668 3.63315 9.19347 3.91315 9.19347Z"
        fill="white"
      />
    </svg>
  );
};
export default Folder;
